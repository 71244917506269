import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { omit } from 'lodash-es';
import React, { useCallback, useState } from 'react';

import CommonDialog from '../CommonDialog';
import LoadingButton from '../LoadingButton';
import Uploader from '../upload/UploaderBase';

import ClientCardImageElement from '@/modules/client/components/ClientCardImageElement';
import ApolloErrorAlert from '@/modules/errors/components/ApolloErrorAlert';
import ErrorAlert from '@/modules/errors/components/ErrorAlert';
import {
  emptyErrorState,
  ErrorState,
  hasErrors,
  partitionValidations,
} from '@/modules/errors/util';
import {
  useDeleteClientImageMutation,
  useGetClientImageQuery,
  useUpdateClientImageMutation,
} from '@/types/gqlTypes';

export type ClientImageUploadDialogProps = {
  clientId: string;
} & DialogProps;

const ClientImageUploadDialog: React.FC<ClientImageUploadDialogProps> = ({
  clientId,
  onClose,
  ...props
}) => {
  const { data: { client } = {}, loading: fetching } = useGetClientImageQuery({
    variables: { id: clientId },
  });

  const [newPhotoSrc, setNewPhotoSrc] = useState<string | undefined>();
  const [newBlobId, setNewBlobId] = useState<string | undefined>();
  const [errors, setErrors] = useState<ErrorState>(emptyErrorState);

  // Handle closing the dialog and clearing out state.
  const handleClose = useCallback<
    (event?: object, reason?: 'backdropClick' | 'escapeKeyDown') => void
  >(
    (evt, reason) => {
      setNewPhotoSrc(undefined);
      setNewBlobId(undefined);
      setErrors(emptyErrorState);
      // use empty event if not provided
      if (onClose) onClose(evt || {}, reason || 'escapeKeyDown');
    },
    [onClose]
  );

  // Delete current client image
  const [deleteImage, { loading: deleting }] = useDeleteClientImageMutation({
    onCompleted: (data) => {
      if (data.deleteClientImage) {
        handleClose(); // close dialog if image was deleted
      }
    },
    onError: (apolloError) => setErrors({ ...emptyErrorState, apolloError }),
  });

  // Update current client image
  const [mutate, { loading: updating }] = useUpdateClientImageMutation({
    onCompleted: (data) => {
      const { errors: validationErrors = [] } = data.updateClientImage || {};
      if (validationErrors.length) {
        setErrors(partitionValidations(validationErrors));
      } else {
        handleClose();
      }
    },
    onError: (apolloError) => setErrors({ ...emptyErrorState, apolloError }),
  });

  const mutationLoading = updating || deleting;

  const handleSave = useCallback(() => {
    if (newBlobId) {
      mutate({ variables: { clientId, imageBlobId: newBlobId } });
    }
  }, [newBlobId, clientId, mutate]);

  const handleDelete = useCallback(
    () => deleteImage({ variables: { clientId } }),
    [clientId, deleteImage]
  );

  if (!fetching && !client) return null;

  return (
    <CommonDialog
      {...omit(props, 'children')}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          p: 2,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        component='div'
      >
        <Typography sx={{ flexGrow: 1 }} variant='h5' component='p'>
          Upload Client Photo
        </Typography>
      </Box>
      <DialogContent>
        {fetching ? (
          <CircularProgress />
        ) : (
          client && (
            <Grid container spacing={2} justifyContent='space-around'>
              {client.image && (
                <>
                  <Grid item>
                    <Typography variant='body2' gutterBottom align='center'>
                      Current Photo
                    </Typography>
                    <ClientCardImageElement client={client} />
                    {client?.image && (
                      <Box>
                        <LoadingButton
                          variant='outlined'
                          color='error'
                          size='small'
                          fullWidth
                          disabled={mutationLoading}
                          startIcon={<DeleteIcon />}
                          onClick={handleDelete}
                          loading={deleting}
                        >
                          Remove Photo
                        </LoadingButton>
                      </Box>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant='body2' gutterBottom align='center'>
                      New Photo
                    </Typography>
                    <ClientCardImageElement url={newPhotoSrc} />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Uploader
                  id='clientImageUploader'
                  onUpload={(upload, file) => {
                    setNewBlobId(upload.signedBlobId);
                    setNewPhotoSrc(URL.createObjectURL(file));
                    setErrors(emptyErrorState);
                  }}
                  onClear={() => {
                    setNewBlobId(undefined);
                    setNewPhotoSrc(undefined);
                    setErrors(emptyErrorState);
                  }}
                />
              </Grid>
              {hasErrors(errors) && (
                <Grid item xs={12}>
                  <Stack gap={2}>
                    <ApolloErrorAlert error={errors.apolloError} />
                    <ErrorAlert errors={errors.errors} fixable />
                  </Stack>
                </Grid>
              )}
            </Grid>
          )
        )}
      </DialogContent>
      <DialogActions sx={{ gap: 2 }}>
        <Button variant='gray' onClick={handleClose} disabled={mutationLoading}>
          Cancel
        </Button>
        <LoadingButton
          loading={updating}
          disabled={!newBlobId || mutationLoading}
          onClick={handleSave}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
};

export default ClientImageUploadDialog;
